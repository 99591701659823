"use client";

import { Icons, cn } from "@watt/common";
import type { ReactElement } from "react";
import React from "react";

export type InputProps = {
  children?: ReactElement;
} & React.ComponentProps<"input">;

const Input: React.FC<InputProps> = ({
  ref,
  className,
  type,
  children,
  ...props
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  const EyeIcon = showPassword ? Icons.eyeOff : Icons.eye;

  return (
    <div className="relative flex w-full items-center">
      <input
        type={showPassword ? "text" : type}
        className={cn(
          "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:font-medium file:text-sm placeholder:text-muted-foreground hover:bg-accent focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:bg-muted disabled:opacity-50",
          className
        )}
        ref={ref}
        {...props}
      />
      {type === "password" && !props.disabled && (
        <div className="-translate-y-1/2 absolute top-1/2 right-3 transform cursor-pointer">
          <EyeIcon className="h-4 w-4" onClick={togglePasswordVisibility} />
        </div>
      )}
      {children && (
        <div className="pointer-events-none absolute right-0 left-0 flex">
          {children}
        </div>
      )}
    </div>
  );
};

Input.displayName = "Input";

export { Input };
