import type * as LabelPrimitive from "@radix-ui/react-label";
import { Slot } from "@radix-ui/react-slot";
import { cn } from "@watt/common";
import { useLogger } from "next-axiom";
import React from "react";
import {
  Controller,
  type ControllerProps,
  type ControllerRenderProps,
  type FieldPath,
  type FieldValues,
  FormProvider,
  type SubmitHandler,
  useFormContext
} from "react-hook-form";

import { Label } from "@/components/ui/label";
import type { UseZodForm } from "@/hooks/use-zod-form";

// TODO: Tidy up needed, Form is used by other example components so created FormWrapper for now
const Form = FormProvider;
// Copied from example https://kitchen-sink.trpc.io/react-hook-form?file=#content
const FormWrapper = <TInput extends FieldValues>(
  props: Omit<React.ComponentProps<"form">, "onSubmit" | "id"> & {
    handleSubmit: SubmitHandler<TInput>;
    form: UseZodForm<TInput>;
  }
) => {
  const log = useLogger();
  const { handleSubmit, form, children, ...passThrough }: typeof props = props;
  return (
    <FormProvider {...form}>
      <form
        {...passThrough}
        id={form.id}
        onSubmit={event => {
          form.handleSubmit(
            async values => {
              try {
                await handleSubmit(values);
              } catch (cause) {
                form.setError("root.server", {
                  message: (cause as Error)?.message ?? "Unknown error",
                  type: "server"
                });
              }
            },
            invalidError => {
              log.error("ui/form.FormWrapper: ", { error: invalidError });
            }
          )(event);
          event.stopPropagation();
        }}
      >
        {children}
      </form>
    </FormProvider>
  );
};

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  name: TName;
};

const FormFieldContext = React.createContext<FormFieldContextValue>(
  {} as FormFieldContextValue
);

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  ...props
}: ControllerProps<TFieldValues, TName>) => {
  type InputOnChangeParams = Parameters<
    NonNullable<React.ComponentProps<"input">["onChange"]>
  >;

  const onChange =
    (field: ControllerRenderProps<TFieldValues, TName>) =>
    (...event: InputOnChangeParams) => {
      // Configure default onChange to support isDirty correctly

      const [eventOrValue] = event;

      // Handle array values directly
      if (Array.isArray(eventOrValue)) {
        field.onChange(eventOrValue);
        return;
      }
      const getValue = (eventOrValue: unknown) => {
        if (
          typeof eventOrValue === "string" ||
          typeof eventOrValue === "number"
        ) {
          // Respect the coercion of the field value for primitive values (e.g., from select)
          return typeof field.value === "number"
            ? Number(eventOrValue)
            : eventOrValue;
        }

        // Handle input change event
        const { target } = eventOrValue as React.ChangeEvent<HTMLInputElement>;

        const valueParser = {
          number: () => target.valueAsNumber,
          date: () => target.valueAsDate,
          default: () => target.value
        };

        return (
          valueParser[target.type as keyof typeof valueParser] ||
          valueParser.default
        )();
      };
      const value = getValue(eventOrValue);
      field.onChange(value);
    };

  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller
        {...props}
        render={({ field, fieldState, formState }) =>
          props.render({
            field: {
              ...field,
              onChange: onChange(field)
            },
            fieldState,
            formState
          })
        }
      />
    </FormFieldContext.Provider>
  );
};

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();

  const fieldState = getFieldState(fieldContext.name, formState);

  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>");
  }

  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState
  };
};

type FormItemContextValue = {
  id: string;
};

const FormItemContext = React.createContext<FormItemContextValue>(
  {} as FormItemContextValue
);

const FormItem: React.FC<React.ComponentProps<"div">> = ({
  ref,
  className,
  ...props
}) => {
  const id = React.useId();

  return (
    <FormItemContext.Provider value={{ id }}>
      <div ref={ref} className={cn("space-y-2", className)} {...props} />
    </FormItemContext.Provider>
  );
};
FormItem.displayName = "FormItem";

const FormLabel: React.FC<React.ComponentProps<typeof LabelPrimitive.Root>> = ({
  ref,
  className,
  ...props
}) => {
  const { error, formItemId } = useFormField();

  return (
    <Label
      ref={ref}
      className={cn(error && "text-destructive", className)}
      htmlFor={formItemId}
      {...props}
    />
  );
};
FormLabel.displayName = "FormLabel";

const FormControl: React.FC<React.ComponentProps<typeof Slot>> = ({
  ref,
  ...props
}) => {
  const { error, formItemId, formDescriptionId, formMessageId } =
    useFormField();

  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={
        !error
          ? `${formDescriptionId}`
          : `${formDescriptionId} ${formMessageId}`
      }
      aria-invalid={!!error}
      {...props}
    />
  );
};
FormControl.displayName = "FormControl";

const FormDescription: React.FC<React.ComponentProps<"p">> = ({
  ref,
  className,
  ...props
}) => {
  const { formDescriptionId } = useFormField();

  return (
    <p
      ref={ref}
      id={formDescriptionId}
      className={cn("text-muted-foreground text-sm", className)}
      {...props}
    />
  );
};
FormDescription.displayName = "FormDescription";

const FormMessage: React.FC<React.ComponentProps<"p">> = ({
  ref,
  className,
  children,
  ...props
}) => {
  const { error, formMessageId } = useFormField();
  const body = error?.message ? String(error.message) : children;

  if (!body) {
    return null;
  }

  return (
    <p
      ref={ref}
      id={formMessageId}
      className={cn("font-medium text-destructive text-sm", className)}
      {...props}
    >
      {body}
    </p>
  );
};
FormMessage.displayName = "FormMessage";

export {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormWrapper,
  useFormField
};
