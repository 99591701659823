"use client";

import * as SwitchPrimitives from "@radix-ui/react-switch";
import { cn } from "@watt/common";
import { type VariantProps, cva } from "class-variance-authority";
import type React from "react";

const rootSwitchVariants = cva("", {
  variants: {
    size: {
      sm: "h-[20px] w-[40px]",
      default: "h-[24px] w-[44px]"
    }
  },
  defaultVariants: {
    size: "default"
  }
});

const thumbSwitchVariants = cva("", {
  variants: {
    size: {
      sm: "h-4 w-4",
      default: "h-5 w-5"
    }
  },
  defaultVariants: {
    size: "default"
  }
});

const Switch: React.FC<
  React.ComponentProps<typeof SwitchPrimitives.Root> &
    VariantProps<typeof rootSwitchVariants>
> = ({ ref, className, size, ...props }) => (
  <SwitchPrimitives.Root
    className={cn(
      "peer inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-input",
      className,
      rootSwitchVariants({ size })
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        "pointer-events-none block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0",
        thumbSwitchVariants({ size })
      )}
    />
  </SwitchPrimitives.Root>
);

Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
